<template>
  <div class="about">
<!-- ---------CSS animation About us-------------- -->
<div class="animation" style="width: 100%;overflow: hidden;padding-top: 82px;">
         <div class="slider">
             <div class="item" style="position: relative;">
               <h4 style="font-size:10vw;font-weight: 800;white-space:nowrap;color:transparent;-webkit-text-stroke: 0.8px #01F0CC;">Our space</h4>
             </div>
             <div class="item"  style="position: relative;">
             <h4 style="font-size:10vw;font-weight: 800;white-space:nowrap;color: #01F0CC;">Our space</h4>
             </div>
             <div class="item" style="position: relative;">
             <h4 style="font-size:10vw;font-weight: 800;white-space:nowrap;color:transparent;-webkit-text-stroke: 0.8px #01F0CC;">Our space</h4>
             </div>
             <div class="item" style="position: relative;">
             <h4 style="font-size:10vw;font-weight: 800;white-space:nowrap;color: #01F0CC;">Our space</h4>
             </div>
             <div class="item" style="position: relative;">
             <h4 style="font-size:10vw;font-weight: 800;white-space:nowrap;color:transparent;-webkit-text-stroke: 0.8px #01F0CC;">Our space</h4>
            </div>
         </div>
   </div>
  <!-- -----------------CSS animation About us end ------------------- -->

  <div class="space_title">
   <div class="title_background">
    <div class="title">
    <h6 style="margin-bottom: 0;font-weight:800px;">關於空間</h6>
    </div>
   </div>
  </div>
 <section class="pg-about section-padding sub-bg">
    <div class="container" style="margin-left: auto;margin-right: auto;">
      <div class="row">
        <div class="space_img1">
          <div class="bg-img radius-10 md-mb50" style="display: flex;justify-content: flex-start;height: auto;margin-left: 1em;">
            <!-- <img id="aboutImage" style="width:100%;height: auto;"> -->
            <img src="imgs/machinearm.jpg" style="width: 100%;height:300px;object-fit: cover;">
            <!-- <img id="aboutImage"> -->
          </div>
        </div>
        <!-- <div class="col-lg-4">
          <div class="bg-img radius-10 md-mb50"><img id="aboutImage"></div>
        </div> -->
        <!-- <div class="col-lg-8">
          <div class="bg-img radius-10"><img id="aboutImage2"></div>
        </div> -->
      </div>
    </div>
    <div class="container2">
      <div class="space_usage1" >
              <div class="space_info" style="color: #01F0CC;">
                <ul style="padding: 0;">
                <li><p>用途：工作坊 講座 空間租借</p></li>
                <hr style="width: 80%; border: 1px solid #01F0CC;">
                <li><p>可容納人數:30人</p></li>
                <hr style="width: 80%; border: 1px solid #01F0CC;">
                <li><p>面積：XX坪</p></li>
                <hr style="width: 80%; border: 1px solid #01F0CC;">
                <li><p>其他說明：</p></li>
                </ul>
              </div>
      </div>

      <div class="about_space1">
      <p class="org-description is-size-4" style="font-weight:bold;color: #01F0CC;text-align: left">【美援大樓空間 1 樓】</p>
      <div v-html="apiData7" style="color: azure;"></div>
      </div>
         <!-- <p class="org-description is-size-4" style="font-weight:bold;margin-top: 2rem;color: #01F0CC;">ABOUT FUTURE VISION LAB<p>
         <p style="color: azure;">OSI is a platform to introduce and share open source technologies consisting of mostly server side scripts, 
         team collaboration tools, and small utility software like our PDF compressors built for Linux desktops users. 
         Contributors range from founders and staff of 3DF working on both work related and personal projects all the way to contributors from our partners and friends. 
         The goal is to sustain a platform for sharing resources in every aspect of running successful open source projects and to participate in the greater Open Source community together.</p> -->
    </div>
</section>

   


    <section class="pg-about section-padding sub-bg" style="margin-top: 3rem;">
    <div class="container" style="margin-left: auto;margin-right: auto;">
      <div class="row">
        <div class="space_img1">
          <div class="bg-img radius-10 md-mb50" style="display: flex;justify-content: flex-start;height: auto;margin-left: 1em;">
            <img src="imgs/secondfloor.jpg" style="width: 100%;height:300px;object-fit: cover;">
          </div>
        </div>
      </div>
    </div>

    <div class="container3" >
               <div class="space_usage2">
              <div class="space_info" style="color: #01F0CC;">
                <ul style="padding: 0;">
                <li><p>用途：工作坊 講座 空間租借</p></li>
                <hr style="width: 80%; border: 1px solid #01F0CC;">
                <li><p>可容納人數:30人</p></li>
                <hr style="width: 80%; border: 1px solid #01F0CC;">
                <li><p>面積：XX坪</p></li>
                <hr style="width: 80%; border: 1px solid #01F0CC;">
                <li><p>其他說明：</p></li>
                </ul>
              </div>
      </div>

      <div class="about_space2">
      <p class="org-description is-size-4" style="font-weight:bold;color: #01F0CC;">【美援大樓空間 2 樓】</p>
      <div v-html="apiData7" style="color: azure;"></div>
      </div>
    </div>  
</section>

<div class="space_imgs">
    <div class="images">
    <img id="aboutImage" style="width:70%;height:auto;position: absolute;">
    <img id="aboutImage2" style="position: absolute;top:5rem;left:30%; width:50%;height:auto;position: absolute;">
    </div>
    <div class="space_img_info">
      <hr style='background-color:white; height:1px; border:none;'>
      <p>科技媒體實驗平台「未來視覺實驗室」技術總監蔡奇宏演算影像創作，搭配柯智豪聲音創作。</p>

      <router-link :to="{ name:'About' }"><button class="read-more">Read More</button></router-link>
    </div>
</div>
           <!-- <div class="container" style="padding:2rem;display: flex;flex-direction: row;justify-content: flex-start;">
               <div class="space_usage" style="width: 40%;height:auto;">
              <div class="space_info" style="color: #01F0CC;">
                <ul style="padding: 0;">
                <li><p>用途：工作坊 講座 空間租借</p></li>
                <hr style="width: 80%; border: 1px solid #01F0CC;">
                <li><p>可容納人數:30人</p></li>
                <hr style="width: 80%; border: 1px solid #01F0CC;">
                <li><p>面積：XX坪</p></li>
                <hr style="width: 80%; border: 1px solid #01F0CC;">
                <li><p>其他說明：</p></li>
                </ul>
              </div>
      </div>

      <div class="about_space2" style="width: 60%;height: auto;right: 0;">
      <p class="org-description is-size-4" style="font-weight:bold;color: #01F0CC;">【美援大樓空間 2 樓】</p>
      <div v-html="apiData7" style="color: azure;"></div>
      </div>
    </div>   -->



    <hr style="color: aliceblue;">
    <info-bar></info-bar>
    <foot-bar2></foot-bar2>
</div>

</template>


<script>
import axios from 'axios';
import { InfoBar, FootBar2} from '../components';
export default {
  name: 'News',
  components: {
     'info-bar':InfoBar,
     'foot-bar2':FootBar2
  },
  data () {
    return {
      msg: 'FUTURE VISION LAB ',
      schedules:[],
      apiData1:{},
      apiData2:{},
      apiData3:{},
      apiData4:{},
      apiData5:{},
      apiData6:{},
      apiData7:{},
    }
  }, 
  mounted(){
    axios.all([
      axios.get('https://unzip-clab-api.clab.org.tw/api/v1/activities?search=FUTURE%20VISION%20LAB%202024'),
      axios.get('https://unzip-clab-api.clab.org.tw/api/v1/schedules/4'),
      axios.get('https://unzip-clab-api.clab.org.tw/api/v1/works/6162e143-f315-4a45-a9b4-830c8b3d8704'),
      axios.get('https://unzip-clab-api.clab.org.tw/activity-photo/dW5kZWZpbmVkLTE2OTE1NzcyMTAyMzA=.jpeg',{responseType:'blob'}),
      axios.get('https://unzip-clab-api.clab.org.tw/activity-photo/dW5kZWZpbmVkLTE2ODI0MTc0NjgwNDM=.jpeg',{responseType:'blob'}),
      axios.get('https://unzip-clab-api.clab.org.tw/api/v1/activities/443e7a5e-6d51-4d39-9bc0-fd71e579da09'),
      axios.get('https://unzip-clab-api.clab.org.tw/api/v1/works/87db0364-e198-4abb-b4e9-d6c3c70ebd68'),
      axios.get('https://unzip-clab-api.clab.org.tw/work-photo/1ae1a839-6db4-42f8-a6e3-831c59623ca7/MDIuU19fMjQxNjY0MTEuanBnLTE2MzMxNzA4MDYzNDgtMzU4Mw==.jpeg',{responseType:'blob'})
    ])
    .then(axios.spread( (response1, response2 ,response3,response4,response5,response6,response7,response8) => {
       //  const reader = FileReader();
      //宣告Filereader
      this.apiData1 = response1.data;
      this.apiData2 = response2.data;
      this.apiData3 = response3.data;
      this.apiData4 = response4.data;
      this.apiData5 = response5.data;
      this.apiData6 = response6.data;
      this.apiData7 = response7.data.work_zh.proposal;
      this.apiData8 = response8.data;
      let imageNode = document.getElementById('aboutImage');
      let imageNode2 = document.getElementById('aboutImage2');
      let imgUrl = URL.createObjectURL(response5.data);
      let imgUrl2 = URL.createObjectURL(response8.data);
      imageNode.src = imgUrl;
      imageNode2.src = imgUrl2;
      
      console.log(this.apiData1);
      console.log(this.apiData2);
      console.log(this.apiData3);
      console.log(this.apiData4);
      console.log(this.apiData5);
      console.log(this.apiData6);
      console.log(this.apiData7);
      console.log(this.apiData8);
    }))
  //  .catch(err=>{
  //    console.log(err.response);
  //    });
  }
}
</script>
<style lang="scss" scoped>
  /* // .org-description {
  //   margin-top: 50px;
  //   padding-bottom: 10px;
  // } */
  .about {
    background-color: black;
    overflow-y: hidden;
  }
  .space_title{
    padding: 10px 10px;
    position: relative;
    width:70%;
    margin-left:auto;
    margin-right:auto;
  }
 @media only screen and (max-width:768px){
  .space_title{
    width:80%;
  }
 }

 
  .title_background{
    width: 6rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: larger;
    background-color: #01F0CC;
    color: black;
    border-radius: 40px;

  }
  .hero-body{
    width: 100%;
    height: auto;
    background-color: rgb(255, 255, 255);
    display:block;
    position: relative;
    overflow: hidden;
  }
  .aboutImage{
    display: block;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    width: 100%;
    bottom: 0;
  }
  
  .container{
    max-width: 960px;
    width: 100%;
    padding-right:var(--bs-gutter-x, .75rem);
    padding-left:var(--bs-gutter-x,.75rem);
    margin-right:auto;
    margin-left:auto;
  }
  .space_usage1{
    width: 40%;
    height:auto;
    padding: 10px;
  }
  .about_space1{
    width: 60%;
    height: auto;
    right: 0;
  }
  .about_space2{
    width: 60%;
    height:auto;
    right: 0;
  }
  .space_usage2{
    width: 40%;
    height:auto;
  }

  .container2{
    max-width: 1280px;
    width: 100%;
    padding-right:var(--bs-gutter-x, .75rem);
    padding-left:var(--bs-gutter-x,.75rem);
    margin-right:auto;
    margin-left:auto;
    padding:2rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 2rem;
  }
  .container3{
    max-width: 1280px;
    width: 100%;
    padding-right:var(--bs-gutter-x, .75rem);
    padding-left:var(--bs-gutter-x,.75rem);
    margin-right:auto;
    margin-left:auto;
    padding:2rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 2rem;
  }
  .space_img1{
    // .col-lg-4
    flex:0 0 auto;
    // width:33.33333333%;
    width:100%;
  }
  .col-lg-8{
    flex:0 0 auto;
    width:66.66666667%;
  }
  .row{
    --bs-gutter-x:1.5rem;
    --bs-gutter-y:0;
    display:flex;
    flex-wrap:wrap;
    margin-top:calc(var(--bs-gutter-y) * -1);
    margin-right:calc(var(--bs-gutter-x) * -.5);
    margin-left:calc(var(--bs-gutter-x) * -.5);
  }
  .section-padding{
    padding:10px 10px;
  }

  img{
    // border-radius: 10px;
    height:290px;
  }
  // .md-mb50{
  //   margin-bottom: 90px !important;
  // }
  .pg-about .bg-img{
    height:220px;
    background-size:cover;
    background-position:center center;
  }
  .slider{
    animation-name:future-vision-lab;
    animation-duration:150s;
    animation-iteration-count:infinite;
    animation-timing-function: linear;
    animation-delay: 0;
    display: flex;
  }
  .item{
    position: relative;
    padding: 0 30px;
    display: flex;
  }
 
  @keyframes future-vision-lab{
    0%{
      transform: translateX(0);
    }
    100%{
      transform: translateX(-100%);
    }
  }
  .videobox{
    position: relative;
    margin-bottom: 5rem;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;

  }

  .videobox iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.space_imgs{
  width:70vw;
  height:40vh;
  display:flex;
  flex-direction: row;
  position: relative;
  justify-content: flex-start;
  margin-left:auto;
  margin-right: auto;
  margin-top:5rem;
  margin-bottom: 5rem;
}
.images{
  width:50%;
  height:50%;
  position: relative;
  display: flex;
  align-items: center;
  // left:10%;
}
.space_img_info{
  color:#01F0CC;
  width:50%;
  height:auto;
  display: flex;
  flex-direction: column;
}
.read-more{
  width:6rem;
  height: auto; 
  border-radius: 30px;
  background: #01F0CC;
  color: black;
  border: none;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: center;
  font-weight: bolder;
}
.read-more:hover{
  background: white;
  color: black;
}
@media only screen and (max-width:728px){
  .container2{
    flex-direction: column;
  }
  .container3{
    flex-direction: column;
  }
  .space_usage1{
    width: 100%;
    margin-left: 1em;
  }
  .space_usage2{
    width: 100%;
    margin-left: 1em;
  }
  .space_imgs{
    width: 90vw;
    height: 50vh;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    margin-top:2rem;
  }
  .aboutImage{
    width: 100%;
    height: 100%;
  }
  .about_space1{
    width: 100%;
  }
  .about_space2{
    width: 100%;
  }
  .images{
    width: 100%;
    height:100%;
    left:10%;
  }
  .space_img_info{
    width:80%;
  }
}




</style>
<style lang="css" scoped src="../bootstrap.min.css">
</style>

