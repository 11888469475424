var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about"},[_vm._m(0),_vm._m(1),_c('section',{staticClass:"pg-about section-padding sub-bg"},[_vm._m(2),_c('div',{staticClass:"container2"},[_vm._m(3),_c('div',{staticClass:"about_space1"},[_c('p',{staticClass:"org-description is-size-4",staticStyle:{"font-weight":"bold","color":"#01F0CC","text-align":"left"}},[_vm._v("【美援大樓空間 1 樓】")]),_c('div',{staticStyle:{"color":"azure"},domProps:{"innerHTML":_vm._s(_vm.apiData7)}})])])]),_c('section',{staticClass:"pg-about section-padding sub-bg",staticStyle:{"margin-top":"3rem"}},[_vm._m(4),_c('div',{staticClass:"container3"},[_vm._m(5),_c('div',{staticClass:"about_space2"},[_c('p',{staticClass:"org-description is-size-4",staticStyle:{"font-weight":"bold","color":"#01F0CC"}},[_vm._v("【美援大樓空間 2 樓】")]),_c('div',{staticStyle:{"color":"azure"},domProps:{"innerHTML":_vm._s(_vm.apiData7)}})])])]),_c('div',{staticClass:"space_imgs"},[_vm._m(6),_c('div',{staticClass:"space_img_info"},[_c('hr',{staticStyle:{"background-color":"white","height":"1px","border":"none"}}),_c('p',[_vm._v("科技媒體實驗平台「未來視覺實驗室」技術總監蔡奇宏演算影像創作，搭配柯智豪聲音創作。")]),_c('router-link',{attrs:{"to":{ name:'About' }}},[_c('button',{staticClass:"read-more"},[_vm._v("Read More")])])],1)]),_c('hr',{staticStyle:{"color":"aliceblue"}}),_c('info-bar'),_c('foot-bar2')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"animation",staticStyle:{"width":"100%","overflow":"hidden","padding-top":"82px"}},[_c('div',{staticClass:"slider"},[_c('div',{staticClass:"item",staticStyle:{"position":"relative"}},[_c('h4',{staticStyle:{"font-size":"10vw","font-weight":"800","white-space":"nowrap","color":"transparent","-webkit-text-stroke":"0.8px #01F0CC"}},[_vm._v("Our space")])]),_c('div',{staticClass:"item",staticStyle:{"position":"relative"}},[_c('h4',{staticStyle:{"font-size":"10vw","font-weight":"800","white-space":"nowrap","color":"#01F0CC"}},[_vm._v("Our space")])]),_c('div',{staticClass:"item",staticStyle:{"position":"relative"}},[_c('h4',{staticStyle:{"font-size":"10vw","font-weight":"800","white-space":"nowrap","color":"transparent","-webkit-text-stroke":"0.8px #01F0CC"}},[_vm._v("Our space")])]),_c('div',{staticClass:"item",staticStyle:{"position":"relative"}},[_c('h4',{staticStyle:{"font-size":"10vw","font-weight":"800","white-space":"nowrap","color":"#01F0CC"}},[_vm._v("Our space")])]),_c('div',{staticClass:"item",staticStyle:{"position":"relative"}},[_c('h4',{staticStyle:{"font-size":"10vw","font-weight":"800","white-space":"nowrap","color":"transparent","-webkit-text-stroke":"0.8px #01F0CC"}},[_vm._v("Our space")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"space_title"},[_c('div',{staticClass:"title_background"},[_c('div',{staticClass:"title"},[_c('h6',{staticStyle:{"margin-bottom":"0","font-weight":"800px"}},[_vm._v("關於空間")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container",staticStyle:{"margin-left":"auto","margin-right":"auto"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"space_img1"},[_c('div',{staticClass:"bg-img radius-10 md-mb50",staticStyle:{"display":"flex","justify-content":"flex-start","height":"auto","margin-left":"1em"}},[_c('img',{staticStyle:{"width":"100%","height":"300px","object-fit":"cover"},attrs:{"src":"imgs/machinearm.jpg"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"space_usage1"},[_c('div',{staticClass:"space_info",staticStyle:{"color":"#01F0CC"}},[_c('ul',{staticStyle:{"padding":"0"}},[_c('li',[_c('p',[_vm._v("用途：工作坊 講座 空間租借")])]),_c('hr',{staticStyle:{"width":"80%","border":"1px solid #01F0CC"}}),_c('li',[_c('p',[_vm._v("可容納人數:30人")])]),_c('hr',{staticStyle:{"width":"80%","border":"1px solid #01F0CC"}}),_c('li',[_c('p',[_vm._v("面積：XX坪")])]),_c('hr',{staticStyle:{"width":"80%","border":"1px solid #01F0CC"}}),_c('li',[_c('p',[_vm._v("其他說明：")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container",staticStyle:{"margin-left":"auto","margin-right":"auto"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"space_img1"},[_c('div',{staticClass:"bg-img radius-10 md-mb50",staticStyle:{"display":"flex","justify-content":"flex-start","height":"auto","margin-left":"1em"}},[_c('img',{staticStyle:{"width":"100%","height":"300px","object-fit":"cover"},attrs:{"src":"imgs/secondfloor.jpg"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"space_usage2"},[_c('div',{staticClass:"space_info",staticStyle:{"color":"#01F0CC"}},[_c('ul',{staticStyle:{"padding":"0"}},[_c('li',[_c('p',[_vm._v("用途：工作坊 講座 空間租借")])]),_c('hr',{staticStyle:{"width":"80%","border":"1px solid #01F0CC"}}),_c('li',[_c('p',[_vm._v("可容納人數:30人")])]),_c('hr',{staticStyle:{"width":"80%","border":"1px solid #01F0CC"}}),_c('li',[_c('p',[_vm._v("面積：XX坪")])]),_c('hr',{staticStyle:{"width":"80%","border":"1px solid #01F0CC"}}),_c('li',[_c('p',[_vm._v("其他說明：")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"images"},[_c('img',{staticStyle:{"width":"70%","height":"auto","position":"absolute"},attrs:{"id":"aboutImage"}}),_c('img',{staticStyle:{"position":"absolute","top":"5rem","left":"30%","width":"50%","height":"auto"},attrs:{"id":"aboutImage2"}})])
}]

export { render, staticRenderFns }